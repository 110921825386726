import { FC } from 'react';

import { Scope } from '@tools/enums';
import { ReactChildren } from '@tools/types';
import { checkForScopes } from '@tools/utils';

interface Props {
    children: ReactChildren;
    requiredScopes: Scope[];
}

export const WithScopes: FC<Readonly<Props>> = ({
    children,
    requiredScopes,
}: Props) => (checkForScopes(requiredScopes) ? <>{children}</> : null);
